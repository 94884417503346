<template>
  <div class="service-status-card p-2 my-2">
    <div class="d-flex justify-content-space-between">
      <div class="flex-row-centered column-gap-4">
        <!-- Event status -->
        <event-status :status="event.state" />
        <span>
          <span><strong>{{ $t('service-status.start-timezone', { thing: selectedTimeZone }) }}</strong> {{ event.readableStartTime }}</span>
          <span class="ml-1"><strong>{{ $t('service-status.end-timezone', { thing: selectedTimeZone }) }}</strong> {{ event.readableEndTime }}</span>
        </span>
      </div>
      <p class="font-weight-400">
        {{ event.eventId }}
      </p>
    </div>
    <!-- Impact text-->
    <p class="mt-1 mb-0 font-weight-500">
      {{ event.impact }}
    </p>

    <!-- Purpose HTML -->
    <el-collapse-transition>
      <div ref="contentDiv"
        class="overflow-hidden"
        :style="`height: ${calculatedPurposeHeight}`">
        <p v-html="event.purposeHTML" /> <!-- eslint-disable-line vue/no-v-html -->
      </div>
    </el-collapse-transition>
    <el-button v-if="showToggleBtn"
      size="small"
      class="mb-1"
      type="primary"
      @click="isExpanded = !isExpanded">
      {{ $t(isExpanded ? 'service-status.less' : 'service-status.more') }}
      <i :class="`fas ${isExpanded ? 'fa-chevron-up' : 'fa-chevron-down'}`"
        aria-hidden="true" />
    </el-button>
      
    <!-- Cancellation reason -->
    <p v-if="event.state === 'Cancelled'"
      class="mt-1 mb-1 font-weight-400"
      v-text="event.cancelReason" />
      
    <!-- Impacted services -->
    <impacted-services :services-data="event.servicesData" />
  </div>
</template>

<script>
import { debounce } from 'lodash'
import { mapGetters } from 'vuex'
import EventStatusComponent from '@/components/service-status/EventStatus.vue'
import ImpactedServicesComponent from '@/components/service-status/ImpactedServices.vue'

export default {
  name: 'MaintenanceEvent',

  components: {
    'event-status': EventStatusComponent,
    'impacted-services': ImpactedServicesComponent,
  },

  props: {
    event: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isExpanded: false,
      showToggleBtn: false,
      contentHeight: 0,
      resizeObserver: null,
    }
  },

  computed: {
    ...mapGetters('ServiceStatus', ['selectedTimeZone']),
    // Calculate the height for purpose text
    calculatedPurposeHeight() {
      // Considering min height 52px
      if (this.isExpanded) {
        return 'auto'
      } else {
        return this.contentHeight > 50 ? '52px' : `${this.contentHeight}px`
      }
    },
  },

  mounted() {
    this.debouncedCheckContentHeight = debounce(this.checkContentHeight, 200)
    this.resizeObserver = new ResizeObserver(() => {
      this.debouncedCheckContentHeight()
    })

    this.resizeObserver.observe(this.$refs.contentDiv)
  },

  beforeDestroy() {
    this.resizeObserver?.disconnect()
  },

  methods: {
    checkContentHeight() {
      const content = this.$refs.contentDiv
      this.contentHeight = content.scrollHeight
      if (this.contentHeight > 50) {
        this.showToggleBtn = true
      }
    },
  },
}
</script>
