<template>
  <div class="service-status-card p-2 my-2">
    <div class="d-flex justify-content-space-between">
      <div class="d-flex column-gap-4 flex-align-center">
        <!-- Event status tag-->
        <event-status :status="eventData.state" />
        <span>
          <strong> {{ $t('service-status.start-timezone', { thing: selectedTimeZone }) }} </strong>{{ eventData.readableStartTime }}
          <span v-if="eventData.endTime"
            class="ml-1">
            <strong>{{ $t('service-status.end-timezone', { thing: selectedTimeZone }) }} </strong>{{ eventData.readableEndTime }}
            <span class="ml-1" />
            <strong>{{ $t('service-status.duration') }}</strong> {{ eventData.duration }}</span>
        </span>
      </div>
      <p class="font-weight-400">
        {{ eventData.eventId }}
      </p>
    </div>
    
    <!-- Resolution Details -->
    <div v-if="eventData.state === 'Resolved'"
      class="my-1">
      <h4 class="pb-1 font-weight-500">
        {{ $t('service-status.resolution-details') }}
      </h4>
      <el-row :gutter="8" 
        class="pb-1">
        <el-col class="text-align-right font-weight-500" 
          :span="3">
          {{ $t('service-status.root-cause') }}
        </el-col>
        <el-col :span="12">
          {{ eventData.rootCause }}
        </el-col>
      </el-row>

      <el-row :gutter="8"
        class="pb-1">
        <el-col class="text-align-right font-weight-500" 
          :span="3">
          {{ $t('service-status.resolution') }}
        </el-col>
        <el-col :span="12">
          {{ eventData.resolution }}
        </el-col>
      </el-row>

      <el-row v-if="eventData.mitigationActions"
        :gutter="8"
        class="pb-1">
        <el-col class="text-align-right font-weight-500" 
          :span="3">
          {{ $t('service-status.mitigation-actions') }}
        </el-col>
        <el-col :span="10">
          {{ eventData.mitigationActions }}
        </el-col>
      </el-row>
    </div>

    <!-- Notification History -->
    <div v-if="eventData.notices" 
      class="mt-1">
      <el-button v-if="eventData.state === 'Resolved'"
        size="small"
        class="mb-1"
        type="primary"
        @click="showNotifications = !showNotifications">
        {{ showNotifications ? $t('service-status.hide-updates') : $t('service-status.show-updates') }}
        <i :class="`fas ${showNotifications ? 'fa-chevron-up' : 'fa-chevron-down'}`"
          aria-hidden="true" />
      </el-button>
      <el-collapse-transition>
        <div v-show="showNotifications">
          <h4 class="mb-1 font-weight-500">
            {{ $t('service-status.update-notifications') }}
          </h4>
          <notification-history v-for="(notice, idx) in eventData.notices"
            :key="notice.createdDate"
            :notice="notice"
            :state="eventData.state"
            :is-initial-notification="idx === eventData.notices.length - 1"
            :is-last-notification="idx === 0" />
        </div>
      </el-collapse-transition>
    </div>
      
    <!-- Impacted services  -->
    <impacted-services :services-data="eventData.servicesData" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import NotificationHistory from '@/components/service-status/outages/NotificationHistory.vue'
import EventStatusComponent from '@/components/service-status/EventStatus.vue'
import ImpactedServicesComponent from '@/components/service-status/ImpactedServices.vue'

export default {
  name: 'OutageEventCard',

  components: {
    'notification-history': NotificationHistory,
    'event-status': EventStatusComponent,
    'impacted-services': ImpactedServicesComponent,
  },

  props: {
    eventData: {
      type: Object,
      required: true,
    },
  },
  
  data() {
    return {
      showNotifications: this.eventData.state !== 'Resolved',
    }
  },

  computed: {
    ...mapGetters('ServiceStatus', ['selectedTimeZone']),
  },
}
</script>
