<template>
  <section v-loading.fullscreen="!servicesReady"
    :element-loading-text="$t('services.loading-services')"
    class="service-tabs">
    <el-tabs v-model="activeTab"
      type="card"
      @tab-click="updateTab">
      <el-tab-pane :label="$t('service-status.maintenance')"
        name="maintenance" />
      <el-tab-pane :label="$t('service-status.outages')"
        name="outages" />
    </el-tabs>

    <!-- Filter & Sort Options -->
    <div class="p-2 card">
      <div class="flex-column flex-wrap row-gap-4">
        <!-- Filter Options -->
        <fieldset class="ssp-fieldset flex-row-centered column-gap-6 flex-1 filter-wrapper">
          <legend class="label">
            {{ $t('service-status.filter') }}
          </legend>
          <el-input clearable
            :value="filter.searchText"
            :placeholder="$t('service-status.search-event-placeholder')"
            @input="updateFilter([['searchText', $event]])" />

          <el-checkbox-group class="d-flex"
            :value="filter.state"
            @input="updateFilter([['state', $event]])">
            <el-checkbox v-for="option in states"
              :key="option.value"
              :label="option.value">
              <div class="d-flex flex-column">
                <span>{{ option.label }} ({{ getEventCount(option.value) }})</span>
                <span v-if="option.helpText"
                  class="help-text">
                  {{ option.helpText }}
                </span>
              </div>
            </el-checkbox>
          </el-checkbox-group>
        </fieldset>

        <div class="flex-row-centered column-gap-4">
          <!-- TimeZone Dropdown -->
          <fieldset class="ssp-fieldset">
            <legend class="label">
              {{ $t('service-status.timezone') }}
            </legend>
            <el-select class="min-width-350px"
              :value="filter.timeZone"
              filterable
              @change="updateFilter([['timeZone', $event]])">
              <el-option v-for="({ label, value, offset }, index) in timeZoneOffsets"
                :key="`timeZone-${index}`"
                :label="label"
                :value="value">
                <template #default>
                  <div class="d-flex justify-content-space-between">
                    <span>{{ label }}</span>
                    <span>{{ offset }}</span>
                  </div>
                </template>
              </el-option>
            </el-select>
          </fieldset>

          <!-- Sort Options -->
          <fieldset class="ssp-fieldset d-flex column-gap-6">
            <legend class="label">
              {{ $t('service-status.sort') }}
            </legend>
            <el-radio-group class="d-flex"
              :value="filter.sortBy"
              @input="updateFilter([['sortBy', $event]])">
              <el-radio-button label="state">
                {{ $t('service-status.status') }}
              </el-radio-button>
              <el-radio-button label="startTime">
                {{ $t('service-status.start-date') }}
              </el-radio-button>
            </el-radio-group>

            <el-radio-group class="d-flex"
              :value="filter.sortDirection"
              @input="updateFilter([['sortDirection', $event]])">
              <el-radio-button label="ascending">
                {{ $t('service-status.ascending') }}
              </el-radio-button>
              <el-radio-button label="descending">
                {{ $t('service-status.descending') }}
              </el-radio-button>
            </el-radio-group>
          </fieldset>

          <!-- Refresh Button -->
          <el-button type="info"
            class="ml-auto"
            :disabled="fetchingEvents"
            @click="refreshEvents">
            <span class="pr-0-25">
              {{ $t('general.refresh') }}
            </span>
            <i class="fas fa-arrows-rotate"
              :class="fetchingEvents ? 'spinning-icon' : ''" />
          </el-button>
        </div>
      </div>

      <!-- Event list -->
      <div v-if="processedEvents.length"
        v-loading="fetchingEvents">
        <div v-if="activeTab === 'maintenance'">
          <maintenance-event v-for="event in processedEvents"
            :key="event.startTime"
            :event="event" />
        </div>

        <div v-if="activeTab === 'outages'">
          <outage-event v-for="outage in processedEvents"
            :key="outage.outageId"
            :event-data="outage"
            :services="outage.servicesData" />
        </div>
      </div>
      <p v-else
        class="no-data color-text-regular">
        {{ $t('service-status.no-data-text') }}
      </p>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { DateTime } from 'luxon'
import MaintenanceEvent from '@/components/service-status/maintenance/MaintenanceEvent.vue'
import OutageEvent from '@/components/service-status/outages/OutageEvent.vue'
import { filterNestedMatch, getFormattedDateFromISO, markdownToHtml, orderBy, timeZoneOffsets } from '@/helpers.js'

export default {
  name: 'ServiceStatus',

  components: {
    'maintenance-event': MaintenanceEvent,
    'outage-event': OutageEvent,
  },

  data() {
    return {
      activeTab: this.$route.query.tab ?? 'maintenance',
      // exclude below fields from filter process
      excludeFields: {
        maintenance: ['purpose', 'state'],
        outages: ['outageId', 'state'],
      },
      timeZoneOffsets,
    }
  },

  computed: {
    ...mapGetters('ServiceStatus', ['allMaintenanceEvents', 'allOutageEvents', 'allServicesShortUidDictionary']),
    ...mapState('ServiceStatus', ['fetchingEvents', 'filters', 'tab']),
    ...mapState('Services', ['servicesReady']),
    states() {
      if (this.activeTab === 'maintenance') {
        return [
          { label: this.$t('service-status.scheduled'), value: 'Scheduled' },
          { label: this.$t('service-status.ongoing'), value: 'Running' },
          { label: this.$t('service-status.completed'), value: 'Completed', helpText: this.$t('service-status.in-last-7-days') },
          { label: this.$t('service-status.cancelled'), value: 'Cancelled', helpText: this.$t('service-status.in-last-7-days') },
        ]
      } else {
        return [
          { label: this.$t('service-status.ongoing'), value: 'Ongoing' },
          { label: this.$t('service-status.resolved'), value: 'Resolved', helpText: this.$t('service-status.in-last-7-days') },
        ]
      }
    },
    filter() {
      return this.filters[this.activeTab]
    },
    allEvents() {
      return {
        maintenance: this.allMaintenanceEvents.map(event => this.transformEvent(event, {
          purposeHTML: markdownToHtml(event.purpose),
        })),
        outages: this.allOutageEvents.map(event => this.transformEvent(event, {
          duration: this.getDifferenceInDates(event.startTime, event.endTime),
        })),
      }
    },
    processedEvents() {
      // Filter by state
      const filteredByState = this.allEvents[this.activeTab].filter(event => this.filter.state.includes(event.state))
      // Filter by text search
      const filteredEvents = filterNestedMatch(filteredByState, this.filter.searchText, this.excludeFields[this.activeTab])
      // Sort the filtered events
      return orderBy(filteredEvents, this.filter.sortBy, this.filter.sortDirection)
    },
  },

  created() {
    if (this.$route.query.tab) {
      const queries = { ...this.$route.query }
      delete queries.tab
      this.updateFilter(Object.entries(queries))
    } else {
      this.activeTab = this.tab
      this.updateQuery()
    }
  },

  methods: {
    getEventCount(state) {
      const eventsList = this.filter.state.includes(state) ? this.processedEvents : this.allEvents[this.activeTab]
      return eventsList.filter(event => event.state === state).length
    },
    getServicesData(servicesArray) {
      return servicesArray.map(uid => {
        const service = this.allServicesShortUidDictionary[uid]
        return {
          type: service.productType,
          title: service.productName,
          shortUid: uid,
          uid: service.productUid,
        }
      })
    },
    getDifferenceInDates(startDate, endDate) {
      const startTime = DateTime.fromISO(startDate)
      const endTime = DateTime.fromISO(endDate)

      const duration = endTime.diff(startTime, ['hours', 'minutes'])
      const hours = Math.floor(duration.as('hours'))
      const mins = duration.minutes % 60

      return `${hours} Hours, ${mins} Minutes`
    },
    transformEvent(event, additionalFields = {}) {
      return {
        ...event,
        servicesData: this.getServicesData(event.services),
        readableStartTime: getFormattedDateFromISO(event.startTime, this.filter.timeZone),
        readableEndTime: getFormattedDateFromISO(event.endTime, this.filter.timeZone),
        ...additionalFields,
      }
    },
    updateQuery() {
      this.$router.replace({
        query: {
          tab: this.activeTab,
          ...this.filter,
        },
      }).catch(() => {})
    },
    updateTab() {
      this.$store.commit('ServiceStatus/updateTab', this.activeTab)
      this.updateQuery()
    },
    updateFilter(newValues) {
      this.$store.commit('ServiceStatus/updateFilters', { tab: this.activeTab, newValues })
      this.updateTab()
    },
    refreshEvents() {
      this.$store.commit('ServiceStatus/setFetchRetriesLeft', 3)
      this.$store.dispatch('ServiceStatus/fetchEnsEvents')
    },
  },
}
</script>

<style lang="scss" scoped>
.card {
  background: #FFFFFF;
}

.service-tabs {
  max-width: 1200px;
  margin: 1rem auto;
  padding: 1rem;
}

@media (max-width: 992px) {
  .filter-wrapper {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 2rem;
  }
}

.help-text {
  margin: 2px 0;
  font-size: 12px;
  align-self: flex-end; 
}

.spinning-icon {
  animation: spin 0.33s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.no-data {
  margin-top: 2em;
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
}

::v-deep .el-tabs__item.is-active {
  background-color: #FFF;
}
</style>
