<template>
  <el-tag :type="tag.type"
    data-testid="tag">
    <span class="font-weight-500">
      <i class="fa-light"
        :class="tag.icon"
        aria-hidden="true" />
      {{ tag.text }}
    </span>
  </el-tag>
</template>

<script>
const statusMap = {
  // For maintenance events
  Running: {
    text: 'Ongoing',
    type: 'danger',
    icon: 'fa-clock-eight',
  },
  // For outage events
  Ongoing: {
    text: 'Ongoing',
    type: 'danger',
    icon: 'fa-clock-eight',
  },
  Scheduled: {
    text: 'Scheduled',
    type: 'warning',
    icon: 'fa-calendar-days',
  },
  Completed: {
    text: 'Completed',
    type: 'success',
    icon: 'fa-check',
  },
  Resolved: {
    text: 'Resolved',
    type: 'success',
    icon: 'fa-check',
  },
  Cancelled: {
    text: 'Cancelled',
    type: 'info',
    icon: 'fa-xmark',
  },
}

export default {
  props: {
    status: {
      type: String,
      required: true,
    },
  },

  computed: {
    tag() {
      return statusMap[this.status] || statusMap.Cancelled
    },
  },
}
</script>
