<template>
  <div class="flex-column row-gap-1 pt-1 w-full">
    <h4 class="font-weight-500 mb-1">
      {{ $t('service-status.impacted-services') }}
    </h4>

    <el-table :data="servicesData"
      :row-class-name="highlightRow"
      max-height="220px">
      <el-table-column prop="type"
        sortable
        :label="$t('service-status.type-label')"
        width="90px">
        <template #default="{ row }">
          <div class="flex-row-centered column-gap-1">
            <mu-mega-icon class="icon"
              :icon="row.type" />
            <span>{{ row.type === 'MEGAPORT' ? 'PORT' : row.type }}</span>
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="title"
        sortable
        :label="$t('service-status.name-label')" />

      <el-table-column prop="shortUid"
        label="ID"
        class="text-align-center"
        sortable
        width="120px">
        <template #default="{ row }">
          <div class="text-align-center">
            <span class="service-identifier copy-padding"
              @click.stop="copyToClipboard(row.shortUid)">
              <span>#{{ row.shortUid }}</span>
              <i class="far fa-clipboard-check ml-1"
                aria-hidden="true" />
            </span>
          </div>
        </template>
      </el-table-column>

      <el-table-column :label="$t('service-status.actions-label')"
        header-align="center"
        align="center"
        width="190px">
        <template #default="{ row }">
          <el-button size="mini"
            @click="navigateToDetails(row.type, row.uid)">
            <i class="far fa-gear pr-0-25"
              aria-hidden="true" />
            {{ $t('service-status.details') }}
          </el-button>

          <el-button v-if="isFeatureEnabled('DASHBOARD_ENABLED')"
            size="mini"
            @click="navigateToMap(row.uid)">
            <i class="far fa-location-dot pr-0-25"
              aria-hidden="true" />
            {{ $t('service-status.map') }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { copyToClipboard } from '@/helpers.js'
import { setFocussedUid } from '@/utils/MapDataUtils'

const serviceTypeMap = {
  VXC: 'edit-connection',
  IX: 'edit-connection',
  MVE: 'mve',
  MCR: 'edit-megaport',
  MCR2: 'edit-megaport',
  MEGAPORT: 'edit-megaport',
}

export default {
  inject: ['isFeatureEnabled'],

  props: {
    servicesData: {
      type: Array,
      required: true,
    },
  },

  methods: {
    copyToClipboard,

    highlightRow({ row }) {
      if (this.$route.query.searchText && `${row.type}-${row.title}-${row.uid}`.toLowerCase().includes(this.$route.query.searchText.toLowerCase())) {
        return 'primary-row'
      }
      return ''
    },

    /**
     * Navigates to the details page of the requested service
     * @param {string} productType Product type of the requested service
     * @param {string} uid Product Uid of the requested service
     */
    navigateToDetails(productType, uid) {
      const pathFromType = serviceTypeMap[productType]
      if (pathFromType) {
        this.$router.push(`/${pathFromType}/${uid}`, () => {})
      } else {
        console.error(productType, 'Could not found')
      }
    },

    /**
     * Navigates to the map page with the requested service focussed
     * @param uid Product Uid of the requested service
     */
    navigateToMap(uid) {
      setFocussedUid(uid)
      this.$router.push('/dashboard')
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-table {
  /* Remove padding from table rows and cells */
  .el-table__row,
  .el-table__row .cell,
  .el-table__cell {
    padding: 4px;
  }
}

.icon {
  width: 24px;
  height: auto;
}

.copy-padding {
  padding: 4px;
  text-align: end;
}
</style>
