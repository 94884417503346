<template>
  <div class="notification-card p-2 my-1">
    <div class="d-flex column-gap-2 flex-align-center">
      <el-tag :type="notificationTag.type">
        <span class="font-weight-500">
          {{ notificationTag.text }}
        </span>
      </el-tag>

      <!-- Start and End Dates -->
      <p class="font-weight-400 m-0 line-height-2">
        {{ getFormattedDateFromISO(notice.createdDate, selectedTimeZone) }} ({{ selectedTimeZone }}):
        <span v-if="isInitialNotification"
          class="ml-1 font-weight-500">
          {{ $t('service-status.notification-description') }}
        </span>
        <span v-else 
          class="ml-1 font-weight-500">{{ notice.description }}</span>
      </p>
    </div>
    
    <!-- Next update & description text -->
    <p v-if="notice.nextUpdate"
      class="mt-1 mb-0 font-weight-400">
      <strong>{{ $t('service-status.next-update-etr') }}</strong> {{ notice.nextUpdate }}
    </p>
    <p v-if="isInitialNotification"
      class="mt-1 mb-0 font-weight-400">
      {{ $t('service-status.next-update-text') }}
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getFormattedDateFromISO } from '@/helpers'

export default {
  name: 'NotificationHistory',

  props: {
    notice: {
      type: Object,
      default: undefined,
    },
    state: {
      type: String,
      default: '',
    },
    isInitialNotification: {
      type: Boolean,
      required: true,
    },
    isLastNotification: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    ...mapGetters('ServiceStatus', ['selectedTimeZone']),
    notificationTag() {
      return {
        text: this.getStatusTagText(),
        type: this.getStatusType(),
      }
    },
  },

  methods: {
    getFormattedDateFromISO,

    getStatusTagText() {
      if (this.isInitialNotification) {
        return this.$t('service-status.incident-notification')
      } else if (this.isLastNotification && this.state === 'Resolved') {
        return this.$t('service-status.resolved-status')
      } else if (this.isLastNotification) {
        return this.$t('service-status.latest-status')
      } else {
        return this.$t('service-status.status-update')
      }
    },

    getStatusType() {
      return this.isInitialNotification ? 'danger' : this.isLastNotification && this.state === 'Resolved' ? 'success' : 'warning'
    },
  },
}
</script>

<style lang="scss" scoped>
.notification-card {
  border: 1px solid #D5D9E2;
  border-radius: 10px;
}
</style>
